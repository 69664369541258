import { lazy, Suspense } from "react";
import { isMobile } from "react-device-detect";
import { Navigate, Route, Routes } from "react-router-dom";
const NavBar = lazy(() => import("../phone/component/navBar/NavBar"));
const WebNavBar = lazy(() => import("../web/component/navBar/NavBar"));
const Home = lazy(() => import("../phone/pages/home/Home"));
const WebHome = lazy(() => import("../web/pages/home/Home"));
const Loading = lazy(() => import("../phone/component/loading/Loading"));
const WebLoading = lazy(() => import("../web/component/loading/Loading"));
const RankingListDetail = lazy(
  () => import("../phone/pages/rankingListDetail/RankingListDetail")
);
const WebRankingListDetail = lazy(
  () => import("../web/pages/rankingListDetail/RankingListDetail")
);
const BookDetailPage = lazy(
  () => import("../phone/pages/bookDetailPage/BookDetailPage")
);
const WebBookDetailPage = lazy(
  () => import("../web/pages/bookDetailPage/BookDetailPage")
);

const Comp = lazy(() => import("../phone/component/comp/Comp"));

export const HomeRouter = () => {
  return isMobile ? (
    <Suspense fallback={<Loading />}>
      <Comp>
        <NavBar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/rank" element={<Home />} />
          <Route path="/book/:id" element={<BookDetailPage />} />
          <Route
            path="/rank/site/:siteName/:rankType?"
            element={<RankingListDetail />}
          />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Comp>
    </Suspense>
  ) : (
    <Suspense fallback={<WebLoading />}>
      <WebNavBar />
      <Routes>
        <Route path="/" element={<WebHome />} />
        <Route path="/rank" element={<WebHome />} />
        <Route path="/book/:id" element={<WebBookDetailPage />} />
        <Route
          path="/rank/site/:siteName/:rankType?"
          element={<WebRankingListDetail />}
        />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Suspense>
  );
};
