import { ReactNode, createContext, useContext, useState } from "react";
import { RankingList } from "../models/rankingList";

interface HomeContextProps {
  rankingLists: RankingList[];
  setRankingLists: React.Dispatch<React.SetStateAction<RankingList[]>>;
}

const HomeContext = createContext<HomeContextProps | undefined>(undefined);

const HomeProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [rankingLists, setRankingLists] = useState<RankingList[]>([]);

  return (
    <HomeContext.Provider value={{ rankingLists, setRankingLists }}>
      {children}
    </HomeContext.Provider>
  );
};

// 自定义 Hook
const useHomeContext = (): HomeContextProps => {
  const context = useContext(HomeContext);
  if (context === undefined) {
    throw new Error("useTheme must be used within a ThemeProvider");
  }
  return context;
};

export { HomeProvider, useHomeContext };
